<template>
  <v-row>
    <v-col cols="12">
      <v-card :color="cor.active.B" v-c:K>
        <v-card-title class="text-left">
          <span>Calendário LAB01</span>
        </v-card-title>
        <v-card-text class="text-left text-justify" v-c:K>
          <v-btn class="mb2" color="primary" x-small @click="parsePessoas">Parse Pessoas</v-btn>
          <v-btn class="mb2" color="primary" x-small @click="parseEventos">Parse Eventos</v-btn>

          <v-file-input show-size label="Planilha" @change="parseExcelFile"></v-file-input>
          <div class="mb2" v-if="abas.length > 0">
            <p>Abas:</p>
            <v-btn class="mr2" color="primary" x-small v-for="(item,index) in abas" :key="index" @click="parseAba(index)">{{item}}</v-btn>
          </div>
          <div id="result1">
            <p v-if="linhas != null">
              linhas ({{abaSelecionada}}) [{{linhas.length}}]:
              <VueObjectView v-model="linhas" :key="refreshKey"/>
            </p>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import dummy from '@/components/dummy/dummy.vue'
import { faker } from '@/components/dummy/faker.js'
import { rdb, snapshotValToArray, generatePushID } from '@/firebasedb.js'
import moment from 'moment';
import XLSX from 'xlsx';
import VueObjectView from 'vue-object-view';
import { roadmap } from "./roadmap.js"

export default {
  name: 'lab01',
  components: { dummy, VueObjectView },
  computed: {
  },
  data () {
    return {
      refreshKey: 0,
      abas: [],
      abaSelecionada: "",
      linhas: null,
      workbook: null,
      reader: null,
      profsByCPF: {}, profsByNome: {}, pessoas: {},
      setores: {}, setoresByNome: {},
    }
  },
  created: function() {
    var self = this;
    this.log("Created");
  },
  mounted: function() {
    var self = this;
    this.log("Mounted");
    window.moment = moment;
    this.loadPessoas();
    this.loadSetores();
  },
  methods: {

    parseXLSX(abaIdx) {
      var arrayBuffer = this.reader.result;
      var options = { type: 'array' };
      this.workbook = XLSX.read(arrayBuffer, options);
      console.timeEnd();
      var sheetName = this.workbook.SheetNames
      this.abas = sheetName;
      console.log(sheetName);
      var sheet = this.workbook.Sheets[sheetName[abaIdx]]
      this.abaSelecionada = sheetName[0];
      console.log(sheet);
      this.linhas = XLSX.utils.sheet_to_json(sheet);
      console.log(this.linhas);
      this.refreshKey += 1;
      this.$forceUpdate();
    },

    parseExcelFile(file) {
      var self = this;
      if(file == null) return;
      console.log("parseExcelFile1",file);
      console.time();
      this.reader = new FileReader();
      this.reader.onloadend = function(event) {
        self.parseXLSX(0);
      };
      this.reader.readAsArrayBuffer(file);
    },

    parseAba(abaIdx) {
      this.parseXLSX(abaIdx)
      this.$forceUpdate();
    },

    loadPessoas() {
      var self = this;
      rdb.ref('/usuarios/pessoas').on('value', function(snapshot) {
        self.pessoas = snapshot.val();
        console.log("self.pessoas",self.pessoas);
        console.log("qtde self.pessoas",Object.keys(self.pessoas).length);
        self.profsByCPF = {};
        for(var key in self.pessoas) {
          if(self.pessoas[key].cpf != undefined) {
            self.profsByCPF[self.pessoas[key].cpf] = self.pessoas[key];
          }
          var nomeprof = self.pessoas[key].nome.toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
          self.profsByNome[nomeprof] = self.pessoas[key];
        }
        console.log("self.profsByNome",self.profsByNome);
        console.log("qtde self.profsByNome",Object.keys(self.profsByNome).length);
        console.log("self.profsByCPF",self.profsByCPF);
        console.log("qtde self.profsByCPF",Object.keys(self.profsByCPF).length);
      });
    },

    loadSetores() {
      var self = this;
      rdb.ref('/calendarios/2022S1/setores').on('value', function(snapshot) {
        self.setores = snapshot.val();
        console.log("self.setores",self.setores);
        console.log("qtde self.setores",Object.keys(self.setores).length);
        self.setoresByNome = {};
        for(var key in self.setores) {
          var nomeSetor = self.setores[key].nome.toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
          self.setoresByNome[nomeSetor] = self.setores[key];
        }
        console.log("self.setoresByNome",self.setoresByNome);
        console.log("qtde self.setoresByNome",Object.keys(self.setoresByNome).length);
      });
    },

    getProfByName(nome) {
      var bestValue = 0;
      var bestNome = "";
      for(var key in this.profsByNome) {
        var value = stringSimilarity.compareTwoStrings(nome,key);
        if(value > bestValue) {
          bestValue = value;
          bestNome = key;
        }
      }
      console.log("buscando nome:",nome);
      console.log("bestNome:",bestNome);
      if(bestValue < 0.7) {
        console.log("["+bestValue+"] ruim: "+nome+" ===> "+bestNome+" ("+this.profsByNome[bestNome].nome+")");
      }
      return clone(this.profsByNome[bestNome]);
    },

    getSetorByName(nome) {
      var bestValue = 0;
      var bestNome = "";
      for(var key in this.setoresByNome) {
        var value = stringSimilarity.compareTwoStrings(nome,key);
        if(value > bestValue) {
          bestValue = value;
          bestNome = key;
        }
      }
      //console.log("buscando nome:",nome);
      //console.log("bestNome:",bestNome);
      if(bestValue < 0.4) {
        console.log("["+bestValue+"] ruim: "+nome+" ===> "+bestNome+" ("+this.setoresByNome[bestNome].nome+")");
        return null;
      }
      return clone(this.setoresByNome[bestNome]);
    },

    getPessoaNomes(nomes) {
      for(var key in this.pessoas) {
        var achei = true;
        for(var i in nomes) {
          var nome = nomes[i].trim().toLowerCase();
          if(this.pessoas[key].nome.trim().toLowerCase().indexOf(nome) == -1) {
            achei = false;
          }
        }
        if(achei) {
          return this.pessoas[key];
        }
      }
      return null;
    },

    getSetor(nome) {
      for(var key in this.setores) {
        if(nome.trim().toLowerCase() == this.setores[key].nome.trim().toLowerCase()) {
          return this.setores[key];
        }
      }
      return null;
    },

    parsePessoas() {
      var self = this;
      var vetPessoas = [];
      var vetChaves = {};
      var equipes = {};

      //var texto = JSON.stringify(this.linhas);
      console.log("this.linhas",this.linhas);

      /*
      var pessoasID = {};
      for(var linha in this.linhas) {
        //console.log("this.linhas[linha]",this.linhas[linha]);
        var vetGestores = [];
        if(this.linhas[linha]['Gestor - VPA'] != undefined) {
          vetGestores = this.linhas[linha]['Gestor - VPA'].trim().split(";");
        }
        for(var i in vetGestores) {
          var nome = vetGestores[i].trim().toUpperCase();
          var user = this.getProfByName(nome);
          if(user != null) {
            pessoasID[vetGestores[i].trim()] = user;
          } else {
            pessoasID[vetGestores[i].trim()] = "não achei";
          }
        }
      }
      console.log("pessoasID",pessoasID);
      window.pessoasID = pessoasID;
      */

      var setores = {};
      for(var linha in this.linhas) {
        //console.log("this.linhas[linha]",this.linhas[linha]);
        var vetSetores = [];
        if(this.linhas[linha]['Responsável (Setor) - VPA'] != undefined) {
          vetSetores = this.linhas[linha]['Responsável (Setor) - VPA'].trim().split(";");
        }
        for(var i in vetSetores) {
          var nome = vetSetores[i].trim().toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
          var setor = this.getSetorByName(nome);
          if(setor != null) {
            setores[vetSetores[i].trim()] = setor;
          } else {
            setores[vetSetores[i].trim()] = "não achei";
          }
        }
      }
      console.log("setores",setores);
      return;

      var roadmap2 = [];
      for(var linha in roadmap) {
        var chave = roadmap[linha].gestores.trim();
        var setor = roadmap[linha].setor.trim().split(";").join("|");
        var setores = roadmap[linha].setor.trim().split(";");
        var vetGestores = roadmap[linha].gestores.trim().split(";");
        var users = {};
        for(var i in vetGestores) {
          var user = pessoasID[vetGestores[i].trim()]
          users[user.id] = {
            id: user.id, email: user.email, nome: user.nome, urlFoto: user.urlFoto
          }
        }
        equipes[chave] = {
          id: id = generatePushID()(),
          chave: chave,
          nome: "ADM. Calendário: "+setor,
          pessoas: users
        }
        var evento = roadmap[linha];
        evento.equipes = {};
        evento.equipes[equipes[chave].id] = equipes[chave];
        delete evento.equipes[equipes[chave].id].chave;

        evento.setores = {};
        for(var k in setores) {
          var set = this.getSetor(setores[k]);
          evento.setores[set.id] = set;
        }
        roadmap2.push(evento);
      }

      //console.log(roadmap2);
      //var texto = JSON.stringify(roadmap2);
      //console.log(texto);
      //return;

      var equipes2 = {};
      for(var key in equipes) {
        var id = generatePushID()();
        equipes2[id] = equipes[key];
        equipes2[id].id = id;
        delete equipes2[id].chave;
      }
      console.log("equipes2",Object.keys(equipes2).length,equipes2);

      var updates = {};
      //for(var key in equipes2) {
      //  updates["/usuarios/equipes/"+key] = equipes2[key];
      //}
      for(var key in roadmap) {
        var elem = roadmap[key];
        var id = generatePushID()();
        elem.id = id;
        var mes = ""+elem.mes2;
        if(mes.trim().length < 2) {
          mes = "0"+mes;
        }
        var ano = ""+elem.ano;
        if(elem.diaInicioEvento == undefined) {
          if(elem.diaFinalEvento == undefined) elem.diaFinalEvento = 1;
          elem.diaInicioEvento = elem.diaFinalEvento;
          elem.tipo = "termino";
          var diaI = ""+elem.diaFinalEvento;
          if(diaI.trim().length < 2) {
            diaI = "0"+diaI;
          }
          var diaF = diaI;
          elem.dataInicio = moment(ano+mes+diaI, "YYYYMMDD").format();
          elem.dataFinal = moment(ano+mes+diaF, "YYYYMMDD").format();
        } else {
          if(elem.diaFinalEvento == undefined) {
            if(elem.diaInicioEvento == undefined) elem.diaInicioEvento = 1;
            elem.diaFinalEvento = elem.diaInicioEvento;
            elem.tipo = "inicio";
            var diaI = ""+elem.diaInicioEvento;
            if(diaI.trim().length < 2) {
              diaI = "0"+diaI;
            }
            var diaF = diaI;
            elem.dataInicio = moment(ano+mes+diaI, "YYYYMMDD").format();
            elem.dataFinal = moment(ano+mes+diaF, "YYYYMMDD").format();
          } else {
            var diaI = ""+elem.diaInicioEvento;
            if(diaI.trim().length < 2) diaI = "0"+diaI;
            var diaF = ""+elem.diaFinalEvento;
            if(diaF.trim().length < 2) diaF = "0"+diaF;
            elem.dataInicio = moment(ano+mes+diaI, "YYYYMMDD").format();
            elem.dataFinal = moment(ano+mes+diaF, "YYYYMMDD").format();
          }
        }
        updates["/calendarios/2021S2/eventos/"+id] = elem;
      }
      console.log(updates);
      rdb.ref().update(updates);
      console.log("agora já era!");

    },

    parseEventos() {
      console.log("this.linhas",this.linhas);

      var setoresNome = {};
      for(var linha in this.linhas) {
        var vetSetores = [];
        if(this.linhas[linha]['Responsável (Setor) - VPA'] != undefined) {
          vetSetores = this.linhas[linha]['Responsável (Setor) - VPA'].trim().split(";");
        }
        for(var i in vetSetores) {
          var nomeSetor = vetSetores[i].trim().toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")
          if(this.setoresByNome[nomeSetor] == undefined) {
            var setor = {
              id: generatePushID()(),
              nome: vetSetores[i].trim(),
              cor: "#808080",
            }
            if(setoresNome[setor.nome] == undefined) {
              setoresNome[setor.nome] = setor;
            }
          }
        }
      }
      console.log("setoresNome",setoresNome);

      console.log("this.setoresByNome",this.setoresByNome);

      var updates = {};
      //for(var key in setoresNome) {
      //  updates["/calendarios/2022S1/setores/"+setoresNome[key].id] = setoresNome[key];
      //}
      //console.log("updates ["+Object.keys(updates).length+"]",updates);
      //rdb.ref().update(updates);
      //console.log("agora já era!");
      //return;

      for(var key in this.linhas) {
        var linha = this.linhas[key];
        var elem = {
          mesano: linha["Mês"],
          mes2: linha["Mês nº"],
          diaInicioEvento: linha["Dia (Início do Evento)"],
          diaFinalEvento: linha["Dia (Final do Evento)"],
          descricao: linha["Descrição do evento"],
          gestores: linha["Gestor - VPA"],
          setor: linha["Responsável (Setor) - VPA"],
          tema: linha["Tema"],
          setores: {},
        }
        if(linha["Alunos (A)"] != undefined) {
          elem.alunos = true;
        }
        if(linha["VPA"] != undefined) {
          elem.VPA = true;
        }
        if(linha["Diretores (D)"] != undefined) {
          elem.diretores = true;
        }
        if(linha["Coordenador (C)"] != undefined) {
          elem.coordenadores = true;
        }
        if(linha["Professor (P)"] != undefined) {
          elem.professores = true;
        }
        if(elem.tema == undefined) {
          elem.tema = "";
        }
        if(elem.gestores == undefined) {
          elem.gestores = "";
        }
        if(elem.setor == undefined) {
          elem.setor = "";
        }
        //setores
        var vetSetores = [];
        if(linha['Responsável (Setor) - VPA'] != undefined) {
          vetSetores = linha['Responsável (Setor) - VPA'].trim().split(";");
        }
        for(var i in vetSetores) {
          var nomeSetor = vetSetores[i].trim().toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
          var setor = this.setoresByNome[nomeSetor];
          if(nomeSetor == "BEM-ESTAR & QUALIDADE ACADEMICA") {
            setor = this.setoresByNome["SAUDE INTEGRAL E AMPLIACAO DA CONSCIENCIA"];
          }
          if(nomeSetor == "CURADORIA & APRENDIZAGEM HIBRIDA - LIVE") {
            setor = this.setoresByNome["CURADORIA & APRENDIZAGEM HIBRIDA"];
          }
          if(nomeSetor == "COORDENADORES DE GRANDE AREA") {
            setor = this.setoresByNome["INTEGRACAO CURRICULAR"];
          }
          if(nomeSetor == "DIRETORES DE MICRORREGIAO") {
            setor = this.setoresByNome["INTEGRACAO CURRICULAR"];
          }
          if(nomeSetor == "GESTORES DE AREA") {
            setor = this.setoresByNome["INTEGRACAO CURRICULAR"];
          }
          if(nomeSetor == "GT GESTORES ANALISTAS") {
            setor = this.setoresByNome["INTEGRACAO CURRICULAR"];
          }
          if(nomeSetor == "PLANEJAMENTO") {
            setor = this.setoresByNome["PLANEJAMENTO ACADEMICO"];
          }
          if(nomeSetor == "RELACIONAMENTO E SERVICOS AO ALUNO") {
            setor = this.setoresByNome["RELACIONAMENTO COM ALUNO"];
          }
          if(nomeSetor == "ESTRATEGIA") {
            setor = this.setoresByNome["ESTRATEGIA & INTEGRACAO"];
          }
          elem.setores[setor.id] = setor;
          delete elem.setores[setor.id].pessoas;
        }
        var id = generatePushID()();
        elem.id = id;
        var mes = ""+elem.mes2;
        if(mes.trim().length < 2) {
          mes = "0"+mes;
        }
        var ano = "2022";//+elem.Ano;
        var meses = [ "jan", "fev", "mar", "abr", "mai", "jun", "jul", "ago", "set", "out", "nov", "dez" ];
        elem.mesano = meses[elem.mes2]+"/2022";
        if(elem.diaInicioEvento == undefined) {
          if(elem.diaFinalEvento == undefined) elem.diaFinalEvento = 1;
          elem.diaInicioEvento = elem.diaFinalEvento;
          elem.tipo = "termino";
          var diaI = ""+elem.diaFinalEvento;
          if(diaI.trim().length < 2) {
            diaI = "0"+diaI;
          }
          var diaF = diaI;
          elem.dataInicio = moment(ano+mes+diaI, "YYYYMMDD").format();
          elem.dataFinal = moment(ano+mes+diaF, "YYYYMMDD").format();
        } else {
          if(elem.diaFinalEvento == undefined) {
            if(elem.diaInicioEvento == undefined) elem.diaInicioEvento = 1;
            elem.diaFinalEvento = elem.diaInicioEvento;
            elem.tipo = "inicio";
            var diaI = ""+elem.diaInicioEvento;
            if(diaI.trim().length < 2) {
              diaI = "0"+diaI;
            }
            var diaF = diaI;
            elem.dataInicio = moment(ano+mes+diaI, "YYYYMMDD").format();
            elem.dataFinal = moment(ano+mes+diaF, "YYYYMMDD").format();
          } else {
            var diaI = ""+elem.diaInicioEvento;
            if(diaI.trim().length < 2) diaI = "0"+diaI;
            var diaF = ""+elem.diaFinalEvento;
            if(diaF.trim().length < 2) diaF = "0"+diaF;
            elem.dataInicio = moment(ano+mes+diaI, "YYYYMMDD").format();
            elem.dataFinal = moment(ano+mes+diaF, "YYYYMMDD").format();
          }
        }
        updates["/calendarios/2022S1/eventos/"+id] = elem;
      }
      console.log("updates ["+Object.keys(updates).length+"]",updates);
      //rdb.ref().update(updates);
      console.log("agora já era!");


    }

  }
}
</script>
